import * as Sentry from '@sentry/node'
const SentryIntegrations = require('@sentry/integrations')

let isProd = process.env.NODE_ENV === 'production'
let config = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
}

// When we're developing locally
if (!isProd) {
  config.beforeSend = () => null

  // Instead, dump the errors to the console
  config.integrations = [
    new SentryIntegrations.Debug({
      // Trigger DevTools debugger instead of using console.log
      debugger: false,
    }),
  ]
}
Sentry.init(config)

export const logError = (err, tag, payload) => {
  try {
    Sentry.configureScope(scope => {
      if (err.message) {
        scope.setFingerprint([err.message])
      }

      if (err.statusCode) {
        scope.setExtra('statusCode', err.statusCode)
      }

      if (tag && tag.type && tag.name) {
        scope.setTag(tag.type, tag.name)
      }

      if (payload) {
        scope.setExtra("payload", payload)
      }
    })

    return Sentry.captureException(err)
  } catch (error) {
    if (!isProd) {
      console.error("Could not log error.", err)
    }
  }
}

export const setUID = uid => {
  Sentry.configureScope(scope => {
    scope.setUser({ 'id': uid })
  })
}

export const setIsBeta = bool => {
  Sentry.configureScope(scope => {
    scope.setTag("isBeta", bool)
  })
}